import './Snakkis.css';
import { __html } from './template';

/**
 * @param {string} action
 * @param {Object} object
 * @returns {void}
 */
const trackEngagement = (action, object) => {
    const extraId = [object.storyId, object.id].filter(Boolean).join(':');
    const custom = object.custom;

    const payload = {
        type: 'Engagement',
        action,
        object: {
            type: 'UIElement',
            id: `snakkis-chatbot${extraId ? `:${extraId}` : ''}`,
            name: object.name,
            contentId: object.contentId,
        },
    };

    if (custom) {
        payload.object['spt:custom'] = custom;
    }

    window.pulse((sdk) => {
        sdk.track('trackerEvent', payload);
    });
};

/**
 * @param {string|URL} url
 * @param {RequestInit} options
 * @param {number} timeout
 * @returns {Promise<Response>}
 */
const fetchWithTimeout = (url, options = {}, timeout = 20000) => {
    return fetch(url, {
        signal: AbortSignal.timeout(timeout),
        credentials: 'include',
        headers: {
            Authorization: window.__Bearer,
        },
        ...options,
    });
};

/**
 * @param {number} ms
 */
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

/**
 * @param {URL} url
 * @returns {void}
 */
const addDebugDays = (url) => {
    if (new URL(window.location.href).searchParams.has('debug_days')) {
        const debugDays = new URL(window.location.href).searchParams.get(
            'debug_days',
        );
        url.searchParams.append('debug_days', debugDays);
    }
};

const nextFrame = () =>
    new Promise((resolve) =>
        requestAnimationFrame(() => requestAnimationFrame(resolve)),
    );

export default class Snakkis {
    // Constructor
    /**
     * @param {string|HTMLElement} target - The target element to render the chatbot in
     */
    constructor(target) {
        this.API_URL = 'https://latest-news-chatbot-bff.prod.sk8s.vg.no/';
        this.loadingElement = null;
        //this.API_URL = 'http://localhost/chat/';

        // We need to add HTML to the target element (or if it's a selector, find it)
        if (typeof target === 'string') {
            const element = document.querySelector(target);
            if (!(element instanceof HTMLElement)) {
                throw new Error('No target element found');
            }
            target = element;
        }
        if (!target) {
            throw new Error('No target element found');
        }

        if (!(target instanceof HTMLElement)) {
            throw new Error('Target must be a string or an HTMLElement');
        }

        this.target = target;

        this._onLoad();
    }

    /**
     * @param {string} text
     * @returns {HTMLSpanElement}
     */
    createFadeInElement(text) {
        const span = document.createElement('span');
        span.textContent = text;
        span.classList.add('fade');

        this.fadeIn(span);
        return span;
    }

    /**
     * @param {string} text
     * @param {HTMLElement} outputElement
     * @param {number} wordsPerSecond
     * @returns {Promise<void>}
     */
    streamText(text, outputElement, wordsPerSecond = 80) {
        return new Promise((resolve) => {
            let index = 0;

            const randomDelay = async () => {
                // Calculate random interval with +-50% variation
                const variation = 0.8 * wordsPerSecond;
                const randomWordsPerSecond =
                    wordsPerSecond + (Math.random() - 0.5) * 2 * variation;
                const interval = 1000 / randomWordsPerSecond;
                await delay(interval);
            };

            const streamNextChar = async () => {
                if (index < text.length) {
                    await randomDelay();

                    const char = text[index];
                    const span = this.createFadeInElement(char);
                    outputElement.appendChild(span);

                    index++;

                    streamNextChar(); // Stream the next character

                    if (index === text.length) {
                        resolve();
                    }
                }
            };

            streamNextChar(); // Start streaming
        });
    }

    /**
     * @param {HTMLElement} element
     * @returns {void}
     */
    async fadeIn(element) {
        await nextFrame();
        element.classList.add('fade-in');
    }

    /**
     * @returns {void}
     */
    saveState() {
        //localStorage.setItem('snakkis.state', JSON.stringify(this.state));
    }

    /**
     * @returns {Object}
     */
    getState() {
        /*const serialized = localStorage.getItem('snakkis.state');
        if (serialized) return JSON.parse(serialized);*/

        return { messages: [], last_opened: 0, last_refresh: 0 };
    }

    /**
     * @param {string} role
     * @param {string} message
     * @returns {void}
     */
    saveMessage(role, message) {
        if (this.state.messages?.find((m) => m.content === message)) {
            return;
        }
        this.state.messages.push({ role: role, content: message });
        this.saveState();
    }

    /**
     * @param {string} key
     * @param {string} value
     * @returns {void}
     */
    setState(key, value) {
        this.state[key] = value;
        this.saveState();
    }

    _onLoad() {
        // Don't load until we have a logged in user
        // Add the HTML to the target
        // this.target.innerHTML = __html;
        if (window.__Bearer) {
            this.target.innerHTML = __html;
            this.render();
            return;
        }

        // Set the username and load everything if we're logged in
        this.getIdentity().then((Identity) =>
            Identity.getUser().then((user) => {
                // Add the HTML to the target now that we know we're logged in
                this.target.innerHTML = __html;

                this.target.querySelector('.username').textContent =
                    user.givenName;

                this.render();
            }),
        );
    }

    toggleDisclaimer() {
        const disclaimer = /** @type {HTMLElement} */ (
            this.target.querySelector('.snakkis-disclaimer')
        );
        disclaimer.hidden = !disclaimer.hidden;
        this.target.style.setProperty(
            'min-height',
            disclaimer.hidden ? '0' : '500px',
        );
    }

    getIdentity() {
        return new Promise((resolve) => {
            if (window.Identity) {
                resolve(window.Identity);
            } else {
                window.addEventListener('identity-initialized', () => {
                    resolve(window.Identity);
                });
            }
        });
    }

    render() {
        this.state = this.getState();
        let state = this.state;
        // Should we automatically refresh (once pr day?)
        // Calculate the epoch for 24 hours ago and check if we should refresh
        const now = Date.now() / 1000;
        if (state.messages.length === 0) {
            // Refresh has failed miserably, try again
            state.last_refresh = 0;
        } else {
            this.showSnakkis();
        }

        if (
            state.last_opened === undefined ||
            state.last_opened < now - 1 * 3600
        ) {
            if (
                state.last_refresh === undefined ||
                state.last_refresh < now - 1 * 3600
            ) {
                this.refresh();
            } else {
                console.log(
                    ' *** NOT Auto-refreshing, not that long since we did it last time',
                    Date.now() / 1000 - state.last_refresh,
                );
            }
        }

        // Loop over messages and add them
        state.messages.forEach((msg) => {
            this.addMessage(msg);
        });

        // Add timestamp if we have one
        if (state.last_refresh) {
            /*
            document.querySelector("#lastUpdated").innerHTML = "Oppdatert: " +
                new Date(state.last_refresh * 1000).toLocaleString();
                */
        }
        this.target
            .querySelector('.ai-disclaimer')
            .addEventListener('click', (evt) => {
                const target = /** @type {HTMLElement} */ (evt.target);
                const closest = target.closest('.ai-disclaimer');
                this.toggleDisclaimer();
                closest.classList.toggle('ai-disclaimer-open');
            });
    }

    getLastSeen() {
        const lastSeen = localStorage.getItem('snakkis.last_seen');
        if (!lastSeen) {
            return null;
        }
        return Math.floor(parseInt(lastSeen, 10) / 1000);
    }

    trackInView() {
        let hasBeenInView = false;
        let inViewTimeout = null;
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    console.log(entry);
                    if (
                        entry.isIntersecting &&
                        !hasBeenInView &&
                        entry.intersectionRatio >= 0.5
                    ) {
                        const storyNames = this.state.messages[0].message.map(
                            (m) => {
                                return m.story_name;
                            },
                        );
                        inViewTimeout = setTimeout(() => {
                            hasBeenInView = true;
                            trackEngagement('View', {
                                name: 'Snakkis Chatbot',
                                contentId: 'chatbot',
                                custom: {
                                    storyNames: storyNames,
                                },
                            });
                            localStorage.setItem(
                                'snakkis.last_seen',
                                Date.now().toString(),
                            );
                        }, 1000);
                    } else if (!entry.isIntersecting && hasBeenInView) {
                        observer.disconnect();
                        trackEngagement('View', {
                            id: 'leave',
                            name: 'Snakkis Chatbot',
                            contentId: 'chatbot',
                        });
                    }

                    if (!entry.isIntersecting && inViewTimeout) {
                        clearTimeout(inViewTimeout);
                    }
                });
            },
            { threshold: [1, 0] },
        );
        observer.observe(this.target);
    }

    /**
     * @param {HTMLElement} msg_elem
     * @param {boolean} retry_button
     * @returns {void}
     */
    reportError(msg_elem, retry_button = true) {
        console.log('SYSTEM MESSAGE, stop refresh and expand');
        this.hideSnakkis();
        // We got a system message, show stuff and stop any refreshing animationas
        this.target.querySelector('.ai-icon').classList.remove('refreshing');

        if (retry_button) {
            // Add a "retry" button to the msg_elem
            const retry = document.createElement('button');
            retry.textContent = 'Prøv igjen';
            retry.classList.add('retryButton');
            retry.addEventListener('click', () => {
                this.refresh();
                msg_elem.remove();
            });
            // Need to add the button after the li has been added.
            setTimeout(() => msg_elem.appendChild(retry), 0);
        }
    }

    /**
     * @param {Object} message
     * @param {boolean} stream
     * @returns {void}
     */
    addMessage(message, stream = false) {
        let role = message.role || 'agent';
        console.log('Adding message', message, 'streaming:', stream);
        const list = this.target.querySelector('.snakkis');
        if (message.type == 'stories') {
            console.log('These are stories, add them', message);
            return this.addStories(message, 'stories');
        }

        if (message.message) message = message.message;

        // We now split messages into the different cases, so we
        // need to find the .story-active element
        let root = this.target.querySelector('.story.active');
        if (stream) {
            let msg = root.querySelector('.streamData');
            if (!msg) {
                this.stream_msg = '';
                msg = document.createElement('li');
                if (role == 'system') {
                    msg.classList.add('system-message');
                    if (msg instanceof HTMLElement) {
                        this.reportError(msg);
                    }
                } else {
                    msg.classList.add('agent-message');
                }
                msg.classList.add('streamData');
                root.querySelector('.chat-messages').appendChild(msg);
            }
            // We're already streaming, just set and fade in
            //this.stream_msg += message.content;
            const span = this.createFadeInElement(message.content);
            msg.appendChild(span);

            //msg.innerHTML = this.stream_msg;
            //this.streamText(message.content, msg);
            list.scrollTop = list.scrollHeight;
            return;
        }

        let msg = document.createElement('li');
        if (role == 'user') {
            msg.classList.add('user-message');
        } else if (role == 'system') {
            msg.classList.add('system-message');
            this.reportError(msg);
        } else {
            msg.classList.add('agent-message');
        }

        if (role == 'assistant') {
            this.streamText(message.content, msg);
        } else {
            msg.innerHTML = message.content;
        }
        console.log('Root is', root);
        root.querySelector('.chat-messages').appendChild(msg);
        list.scrollTop = list.scrollHeight;
    }

    /**
     * @param {string} name
     * @returns {DocumentFragment}
     * @throws {Error}
     */
    getTemplateClone(name) {
        const template = /** @type {HTMLTemplateElement} */ (
            this.target.querySelector(`template.${name}`)
        );
        if (!template) {
            throw new Error(`Template "${name}" not found`);
        }
        return /** @type {DocumentFragment} */ (
            template.content.cloneNode(true)
        );
    }

    /**
     * @param {string} storyId
     * @returns {HTMLElement}
     */
    hasStoryElement(storyId) {
        return this.target.querySelector(`.story[data-story-id="${storyId}"]`);
    }

    getCheckIcon() {
        return this.getTemplateClone('check-icon');
    }

    /**
     * @param {string} storyId
     * @returns {HTMLElement}
     */
    getStoryElement(storyId) {
        const existing = this.hasStoryElement(storyId);

        console.log({ existing });

        if (existing instanceof HTMLElement) {
            return existing;
        }

        const clone = this.getTemplateClone('story');
        const storyElem = /** @type {HTMLElement} */ (
            clone.querySelector('.story')
        );
        storyElem.dataset.storyId = storyId;
        storyElem.id = storyId;

        return storyElem;
    }

    async hideSnakkis() {
        const snakkis = this.target;
        snakkis.style.setProperty(
            'max-height',
            snakkis.getBoundingClientRect().height + 'px',
        );
        snakkis.style.setProperty('overflow', 'hidden');
        await delay(250);
        await nextFrame();
        snakkis.style.setProperty('max-height', '0');
        snakkis.addEventListener('transitionend', () => {
            snakkis.classList.remove('snakkis-open');
        });
    }

    async showSnakkis() {
        const snakkis = this.target;
        snakkis.style.setProperty('max-height', '0px');
        snakkis.classList.add('snakkis-open');
        await delay(250);
        await nextFrame();
        snakkis.style.setProperty('max-height', '200px');
        snakkis.style.setProperty('overflow', 'hidden');
        /*snakkis.addEventListener(
            'transitionend',
            () => {
                snakkis.style.setProperty('max-height', 'none');
                snakkis.style.removeProperty('overflow');
            },
            { once: true },
        );*/
    }

    /**
     * @param {Object} message
     * @param {Object} _ - unused
     */
    addStories(message, _) {
        console.log('addStories', message, _);
        // If these are fresh, flag it

        if (message.message) message = message.message;

        if (message.length === 0) {
            this.hideSnakkis();
            return;
        }

        this.trackInView();
        this.showSnakkis();

        // We base it on the 'story' class template
        message.forEach((story, index) => {
            // Add to the selector too
            const hl = document.createElement('button');
            hl.classList.add('headline');
            hl.textContent = story.story_name;
            hl.role = 'tab';
            hl.setAttribute('aria-selected', 'false');
            hl.setAttribute('aria-controls', story.uuid);
            hl.addEventListener('click', () => {
                hl.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
                // We turn off all active headlines and stories and turn on this one
                this.target
                    .querySelectorAll('.headline')
                    .forEach((h) => h.classList.remove('active'));
                hl.classList.add('active');
                hl.setAttribute('aria-selected', 'true');
                trackEngagement('Click', {
                    storyId: story.uuid,
                    contentId: 'headline',
                    id: `headline`,
                    name: story.story_name,
                });
                this.addStory(story);
            });
            this.target.querySelector('.story-selection').appendChild(hl);

            const storyElem = this.getStoryElement(story.uuid);
            this.target.querySelector('.story-list').appendChild(storyElem);

            if (index === 0) {
                hl.classList.add('active');
            }
        });

        this.target.querySelector('.story-list-items').hidden = false;
        (async () => {
            for (const story of message) {
                await this.renderStory(story);
            }
        })();

        const [story] = message;
        //this.addStory(story);
        const showMore = /** @type {HTMLElement} */ (
            this.target.querySelector('.snakkis-show-more')
        );
        showMore.hidden = false;
        showMore.addEventListener('click', (event) => {
            const target = /** @type {HTMLElement} */ (event.target);
            const showMore = target.textContent === 'Vis mer';
            target.textContent = showMore ? 'Vis mindre' : 'Vis mer';
            trackEngagement('Click', {
                storyId: story.uuid,
                contentId: showMore ? 'show-more' : 'show-less',
                id: showMore ? 'show-more' : 'show-less',
                name: story.story_name,
            });
            this.target.classList.toggle('snakkis-expanded');
            this.target.style.setProperty(
                'max-height',
                this.target.classList.contains('snakkis-expanded')
                    ? this.target.scrollHeight + 'px'
                    : '200px',
            );
            this.target.addEventListener('transitionend', () => {
                this.target.style.setProperty('max-height', 'none');
            });
        });

        showMore.addEventListener('pointerup', () => null);
    }

    async renderStory(story) {
        const template = this.getTemplateClone('story-list-item');
        const id = `story-${story.uuid}`;
        template.querySelector('.story-list-item').id = id;
        this.target.querySelector('.story-list-items').appendChild(template);

        const element = this.target.querySelector(`#${id}`);
        await this.streamText(story.story_name, element.querySelector('h3'));
        //await this.streamText(story.short_summary, element.querySelector('p'));
        const summaryEl = element.querySelector('p');
        summaryEl.classList.add('summary-loading');
        const personalStory = await this.getPersonalStory(story.uuid);

        const link = element.querySelector('a');
        link.setAttribute(
            'href',
            `https://www.vg.no/i/${personalStory.source}`,
        );
        link.addEventListener('click', () => {
            trackEngagement('Click', {
                storyId: personalStory.uuid,
                contentId: 'source',
                id: `source`,
                name: personalStory.source,
            });
        });
        console.log(personalStory);
        await this.streamText(personalStory.summary, summaryEl);
        summaryEl.classList.remove('summary-loading');
        this.fadeIn(link);
    }

    /**
     * @param {Object} story
     */
    async addStory(story, storyElem) {
        console.log('Adding story', story);
        if (!storyElem) {
            storyElem = this.getStoryElement(story.uuid);
        }
        this.target.querySelectorAll('.story').forEach((h) => {
            h.classList.remove('active');
            h.setAttribute('aria-hidden', 'true');
        });
        storyElem.classList.add('active');
        storyElem.setAttribute('aria-hidden', 'false');

        console.log('Story element', storyElem, story.uuid);

        if (
            /loading|complete/.test(
                this.hasStoryElement(story.uuid)?.dataset?.state,
            )
        ) {
            return;
        }

        storyElem.dataset.state = 'loading';

        try {
            story = await this.getPersonalStory(story.uuid);
        } catch (error) {
            console.error('Error fetching story:', error);
        }

        const streamedText = this.streamText(
            story.short_summary,
            storyElem.querySelector('.summary'),
        ).then(() => {
            storyElem.querySelector('.summary').classList.add('summary-done');
            storyElem
                .querySelectorAll('.questions button')
                .forEach((b) => b.removeAttribute('disabled'));
        });
        // clone.querySelector('.summary').textContent = story.summary;
        storyElem.querySelector('.source a').setAttribute('href', story.source);

        const msgs = this.state.messages.filter(
            (msg) =>
                msg.storyId === story.uuid && msg.type === 'personal_story',
        );
        const questionsElem = /** @type {HTMLElement} */ (
            storyElem.querySelector('.questions')
        );
        streamedText.then(() => this.startLoading(questionsElem));
        this.getPersonalQuestions(story.uuid, msgs).then((questions) => {
            this.finishedLoading();
            questions.forEach((question, index) => {
                const clone = this.getTemplateClone('question');
                clone.querySelector('.question-text').textContent =
                    question.question;
                const button = clone.querySelector('button');
                if (index > 0) {
                    button.style.setProperty(
                        '--transition-delay',
                        `${index * 0.25}s`,
                    );
                }
                button.addEventListener('click', () => {
                    /*this.sendMessage({
                        type: 'sendMessage',
                        text: question.question,
                        storyId: story.uuid,
                    });*/
                    this.addMessage({
                        role: 'user',
                        message: { content: question.question },
                    });

                    this.startLoading(
                        storyElem.querySelector('.chat-messages'),
                    );
                    delay(1000).then(() => {
                        this.finishedLoading();
                        this.addMessage({
                            role: 'assistant',
                            message: { content: question.answer },
                        });
                    });

                    /*this.startLoading(
                        storyElem.querySelector('.chat-messages'),
                    );*/
                    button.disabled = true;
                    button.addEventListener('transitionend', () => {
                        button.remove();
                    });
                    trackEngagement('Click', {
                        storyId: story.uuid,
                        contentId: 'question',
                        id: `question`,
                        name: question.question,
                    });

                    delay(1000).then(() => {
                        if (this.checkAllQuestionsAnswered()) {
                            this.renderCompletedStory();
                        }
                    });
                });
                nextFrame().then(() => (button.disabled = false));
                questionsElem.appendChild(clone);
            });
        });

        storyElem.appendChild(questionsElem);
        storyElem.dataset.state = 'complete';
    }

    /**
     * @param {string} storyId
     * @returns {Promise<Object>}
     */
    async getPersonalStory(storyId) {
        const url = new URL(this.API_URL + 'story');
        url.searchParams.append('uuid', storyId);
        url.searchParams.append('personal', 'true');
        addDebugDays(url);
        const response = await fetchWithTimeout(url, {}, 10000);
        const data = await response.json();

        if (data?.message) {
            const message = data.message;
            // We reformat the personal story if it is a list of strings
            // We create a <ul> of it
            if (typeof message.personal_summary === 'object') {
                message.personal_summary = message.personal_summary
                    .map((item) => `<li>${item}</li>`)
                    .join('');
                message.personal_summary = `${message.personal_summary}`;
            }
            this.state.messages.push({
                role: 'assistant',
                type: 'personal_story',
                storyId: message.uuid,
                message: message.short_summary,
            });
            return {
                uuid: message.uuid,
                summary: message.short_summary,
                source: message.source,
                follow_up_questions: message.follow_up_questions,
            };
        } else {
            throw new Error('No story found');
        }
    }

    /**
     * @param {string} storyId
     * @param {Object[]} messages
     * @returns {Promise<Object[]>}
     */
    async getPersonalQuestions(storyId, messages) {
        const url = new URL(this.API_URL + 'story_questions');
        url.searchParams.append('uuid', storyId);
        if (messages.length > 0)
            url.searchParams.append('update', JSON.stringify(messages));
        const response = await fetchWithTimeout(url, {}, 10000);
        const data = await response.json();

        const questions = data?.message || [];

        return questions;
    }

    /**
     * @param {Object} message
     * @param {string} storyId
     * @returns {Promise<void>}
     */
    async sendMessage(message) {
        //this.saveMessage('user', message.text);
        // Send message to your API (replace with your actual API call)

        // The call we are making is http GET with arguments "question" and
        // "messages", where "messages" are the stored ones.

        // We must fix the this.state - any "stories" must be rewritten
        let msgs = [];
        this.state.messages.forEach((msg) => {
            if (msg.storyId === message.storyId) {
                if (msg.type === 'personal_story') {
                    msgs.push({ role: 'assistant', content: msg.message });
                } else if (msg.type === 'stories') {
                    msg.message.forEach((story) => {
                        msgs.push({
                            role: 'assistant',
                            content: story.summary,
                        });
                    });
                } /* else */
                /*if (msg.role === 'user') {
                    msgs.push(msg);
                }*/
            }
        });

        msgs.push({ role: 'user', content: message.text });

        //this.saveState();

        console.log('Sending messages', msgs);

        const url = new URL(this.API_URL + 'chat');
        url.searchParams.append('timestamp', '0');
        url.searchParams.append('stream', 'True');
        url.searchParams.append('messages', JSON.stringify(msgs));
        addDebugDays(url);
        try {
            const response = await fetchWithTimeout(url);
            this.finishedLoading();
            this.stream(response);
        } catch (error) {
            console.error('Error sending message to API:', error);
            this.addMessage({
                role: 'system',
                content: 'Could not contact backend, is Appgate running?',
            });
        }
    }

    /**
     * @param {HTMLElement} parent
     * @returns {void}
     */
    startLoading(parent) {
        if (this.loadingElement) {
            return;
        }

        this.target.ariaBusy = 'true';
        this.loadingElement = document.createElement('div');
        this.loadingElement.classList.add('chat-loading');
        parent.appendChild(this.loadingElement);
    }

    finishedLoading() {
        console.log(this.loadingElement?.parentElement, 'finito');
        this.target.ariaBusy = 'false';
        this.loadingElement?.remove();
        this.loadingElement = null;
    }

    checkAllQuestionsAnswered() {
        const questions = /**@type {NodeListOf<HTMLButtonElement>} */ (
            this.target.querySelectorAll('.story.active button.question')
        );
        const answered =
            questions.length === 0 ||
            Array.from(questions).every((q) => q.disabled);
        return answered;
    }

    renderCompletedStory() {
        const storyElem = this.target.querySelector('.story.active');
        const storyTitleElem = this.target.querySelector('.headline.active');
        storyTitleElem.classList.add('headline-completed');
        storyTitleElem.insertAdjacentElement(
            'afterbegin',
            this.getCheckIcon().firstElementChild,
        );
        const nextTitleElem = /** @type {HTMLButtonElement} */ (
            storyTitleElem.nextElementSibling
        );

        const storyTitle = storyTitleElem.textContent;
        const template = this.getTemplateClone('status-message');
        const messageText = /** @type {HTMLElement} */ (
            template.querySelector('.status-message-text')
        );
        messageText.textContent = `Du er nå oppdatert på ${storyTitle}`;
        messageText.insertAdjacentElement(
            'afterbegin',
            this.getCheckIcon().firstElementChild,
        );
        if (nextTitleElem) {
            const button = /** @type {HTMLButtonElement} */ (
                template.querySelector('.status-message-button')
            );
            button.hidden = false;
            const buttonText = template.querySelector('.status-message-story');
            buttonText.textContent = nextTitleElem.textContent;
            const goToNext = () => {
                nextTitleElem.focus();
                nextTitleElem.click();
                trackEngagement('Click', {
                    storyId: nextTitleElem.getAttribute('aria-controls'),
                    contentId: 'NextStory',
                    id: `NextStory`,
                    name: nextTitleElem.textContent,
                });
            };
            button.addEventListener('click', goToNext);
            messageText.addEventListener('click', goToNext);
        }
        this.fadeIn(template.querySelector('.status-message'));
        storyElem.appendChild(template);
    }

    /**
     * @param {Response} response
     * @returns {Promise<void>}
     * @private
     */
    stream(response) {
        // Want to stream these
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let chatMessage = '';

        const readChunk = () => {
            return reader.read().then(({ done, value }) => {
                if (done) {
                    console.log('Streamed Message is completed:', chatMessage);
                    //self.state.messages.push({role: "assistant", content: chatMessage});
                    //self.state.last_refresh = Math.floor(Date.now() / 1000);
                    //self.saveState();

                    let msg = this.target.querySelector('.streamData');
                    if (msg) {
                        msg.classList.remove('streamData');
                    }

                    if (this.checkAllQuestionsAnswered()) {
                        this.renderCompletedStory();
                    }

                    // All done
                    return;
                }

                const message = decoder.decode(value, { stream: true });
                // message is a jsonl string, split and iterate
                const messages = message.split('\n');
                console.log('Split into', messages);
                messages.forEach((message) => {
                    if (message.trim() === '') {
                        return;
                    }

                    let txt = '';
                    try {
                        txt = JSON.parse(message.trim()).message;
                        chatMessage += txt;
                    } catch (e) {
                        console.log('Could not parse message', message);
                        chatMessage +=
                            'Oida, her skjedde det en feil, prøv igjen!';
                    }

                    this.addMessage({ role: 'assistant', content: txt }, true);
                });
                return readChunk();
            });
        };
        return readChunk();
    }

    async refresh() {
        console.log('Clearing all messages and refreshing');

        this.target.querySelector('.ai-icon').classList.add('refreshing');
        let div = this.target.querySelector('.streamData');
        if (div) div.remove();

        // We reset all messages and get a new update from the server
        /*let last_refresh =
            this.state.last_refresh ||
            Math.floor(this.getEpochFromHoursAgo(24 * 7) / 1000);
        this.state.last_refresh = Math.floor(Date.now() / 1000);
        this.saveState();*/
        this.target.querySelector('svg path').setAttribute('fill', '#a96ed5');

        const url = new URL(this.API_URL + 'update');
        url.searchParams.append('stream', 'False');
        //url.searchParams.append('personal', 'true');
        if (this.getLastSeen())
            url.searchParams.append('timestamp', this.getLastSeen().toString());
        this.startLoading(this.target.querySelector('.story-list'));
        addDebugDays(url);

        try {
            const request = await fetchWithTimeout(url);
            if (!request.ok) {
                console.log('NOT OK');
                // Change the color of the ai_logo
                this.target
                    .querySelector('svg path')
                    .setAttribute('fill', 'red');
                throw new Error('Network response was not ok');
            }

            const response = await request.json();
            this.state.messages = [];
            // Clear the main div
            this.finishedLoading();
            this.target.querySelector('.story-selection').innerHTML = '';
            this.target.querySelector('.story-list').innerHTML = '';
            this.target
                .querySelector('.ai-icon')
                .classList.remove('refreshing');

            /*if (false) {
            stream(response);
            this.saveState();
            return;
        }*/

            console.log('Got response', response);
            // Save this time in the state

            // If the message is a string, parse it
            if (response.message && typeof response.message === 'string') {
                response.message = JSON.parse(response.message);
            }
            // It's all an assistant response, just send it
            this.state.messages.push({
                role: 'assistant',
                type: 'stories',
                storyId: response.message.uuid,
                message: response.message,
            });
            this.state.isUnread = true;
            this.saveState();

            this.addStories(response.message, 'response');
        } catch (error) {
            console.error(' **** Error sending message to API:', error);
            this.addMessage({
                role: 'system',
                content: 'Could not contact backend, is Appgate running?',
            });
        }
    }
}
